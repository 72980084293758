<template>
  <div>
    <b-card title="Güncelle">
      <b-form-group label="Kuruluş Adı">
        <b-form-input
          v-model="contractedCompany.title"
          placeholder="Kuruluş Adı"
        />
      </b-form-group>
      <b-form-group
        label="Durum"
        label-for="status"
      >
        <v-select
          id="status"
          v-model="contractedCompany.status"
          placeholder="Durum"
          :options="statuses"
          label="title"
          :reduce="status => status.id"
        />
      </b-form-group>
      <b-form-group label="Metin">
        <b-form-textarea
          v-model="contractedCompany.content"
          placeholder="Metin"
        />
      </b-form-group>

    </b-card>
    <b-card title="Görsel (Logo)">
      <b-row>
        <b-col
          v-if="imageUrl"
          xs="12"
          md="3"
        >
          <b-img
            :src="imageUrl"
            fluid
          />
        </b-col>
        <b-col>
          <b-form-group label="Görsel (Logo) Yükle">
            <b-form-file
              v-model="contractedCompany.upload_image"
              placeholder="Bir görsel seçin veya buraya bırakın..."
              drop-placeholder="Görseli buraya bırakın..."
              browse-text="Görsel Seçin"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BImg,
  BCard,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BFormGroup,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Add',
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BFormGroup,
    BButton,
    vSelect,
  },
  data() {
    return {
      submitStatus: false,
      imageUrl: null,
      statuses: [
        {
          id: '1',
          title: 'Açık',
        },
        {
          id: '0',
          title: 'Kapalı',
        },
      ],
    }
  },
  computed: {
    contractedCompany() {
      const data = this.$store.getters['contractedCompanies/getContractedCompany']
      if (data.image) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.imageUrl = `${this.$store.state.app.baseURL}/media/portal/contracted_companies/${data.image}`
      }
      return data
    },
    saveStatus() {
      return this.$store.getters['contractedCompanies/getContractedCompanySaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.getContractedCompanies()
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.contractedCompany.submitStatus = false
    },
  },
  beforeCreate() {
    this.$store.dispatch('contractedCompanies/contractedCompanyReset')
  },
  created() {
    this.getContractedCompanies()
  },
  methods: {
    getContractedCompanies() {
      this.$store.dispatch('contractedCompanies/contractedCompanyView', this.$route.params.id)
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.contractedCompany.submitStatus = true
      this.$store.dispatch('contractedCompanies/contractedCompanySave', this.contractedCompany)
    },
  },
}
</script>
